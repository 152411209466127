import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'moment/locale/pt-br';

// root reducer
import RootReducer from './RootReducer';

// configurações
import './scss/geral.scss';
import './icones';
import './axiosConfig';
import axios from 'axios';


const config = {
    blacklist: []
};
const middlewares = [
    createStateSyncMiddleware(config),
];
const store = createStore(RootReducer, {}, applyMiddleware(...middlewares));
initStateWithPrevTab(store);

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
