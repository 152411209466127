import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Button, Toast, Row, Col, Modal, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {Link, useParams, Redirect, BrowserRouter as Router, useHistory} from 'react-router-dom';
import logo from './../../assets/midiaLocalLogo.png';
import logoIcone from './../../icones/svg/logo2.svg'
// import './RedefinirSenha.scss';
import Carregador from './../Componentes/Carregador';
import { AssociadosVigentesComponent } from './components';
import moment from "moment";

export default function AssociadosVigentes(){


    const [ registros, alterarRegistros ] = useState([]);


    useEffect(() => {
        consultarRegistros();
    }, [])


    return <AssociadosVigentesComponent className="container-fluid login-pagina">
        <div
            className="row d-md-flex" 
        >
            <div md={3} className="logo">
                <img src={logoIcone}/>
            </div>
            <div className="col-md-10 col-lg-8 m-auto py-5">
                <div className="d-flex" style={{height: "100vh", overflowX: "hidden", overflowY: "scroll"}}>
                    <div>
                        <Table className="table">
                            <tbody>
                                {registros.map(r => 
                                    <tr key={r.nome}>
                                        <td style={{textTransform: "capitalize"}}>{r.nome.toLowerCase()}</td>
                                        <td>{r.email}</td>
                                        <td>{moment(r.expira).format("MM/YYYY")}</td>
                                        <td>{r.telefone} | {r.celular} | {r.telefoneComercial}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>

                </div>
            </div>

        </div>
    </AssociadosVigentesComponent>


    async function consultarRegistros(){

        const { data } = await axios.get(`/vigentes`);
        alterarRegistros(data.registros);

    };

};